import React, { useState } from 'react';
import { QuoteWrapper, QuoteForm } from './styled/quote.style';

const Quote = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <QuoteWrapper>
      {submitted ? (
        <h2>Thank you! Your quote request has been submitted.</h2>
      ) : (
        <QuoteForm onSubmit={handleSubmit}>
          <h2>Request a Quote</h2>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="tel" id="phone" name="phone" required />
          </div>
          <div className="form-group">
            <label htmlFor="location">Job Location</label>
            <input type="text" id="location" name="location" required />
          </div>
          <div className="form-group">
            <label htmlFor="description">Job Description</label>
            <textarea id="description" name="description" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </QuoteForm>
      )}
    </QuoteWrapper>
  );
};

export default Quote;
