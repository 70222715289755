import styled from 'styled-components';

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const QuoteForm = styled.form`
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  h2 {
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 1rem;
  }

  textarea {
    resize: none;
    min-height: 120px;
  }

  button {
    background-color: #333;
    color: #fff;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;&:hover {
      background-color: #555;
    }
    }

    @media (max-width: 768px) {
    .form-group {
    margin-bottom: 10px;
    }
  }
  `;