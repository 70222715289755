import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import kmkUpscale from './images/kmkupscale.jpg';
import comm4 from './images/comm4.jpg';
import res1 from './images/res1.jpg';
import grid1 from './images/grid1.jpg';
import {
  HomeWrapper,
  LogoWrapper,
  ServicesWrapper,
  ServiceCard,
} from './styled/home.style';

const Home = () => {
  return (
    <HomeWrapper>
      <LogoWrapper>
        <img src={kmkUpscale} alt="KMK Drywall Logo" />
      </LogoWrapper>
      <p>Contact us at <a href='tel:+17163532989'>(716) 353-2989</a> or <a href="mailto:kmkdrywall8900@gmail.com">kmkdrywall8900@gmail.com</a></p>
      <ServicesWrapper>
        <ServiceCard>
          <h3>Commercial Drywall Installation and Finishing</h3>
          <img
            src={comm4}
            alt='Commercial Drywall'
          />
          <p>
            We are experts in commercial drywall installation and finishing.
            Our team can handle any job of any size, from small offices to large
            commercial buildings.
          </p>
        </ServiceCard>
        <ServiceCard>
          <h3>Residential Drywall Installation and Finishing</h3>
          <img
            src={res1}
            alt='Residential Drywall'
          />
          <p>
            We also provide residential drywall installation and finishing
            services. Whether it's a new construction or a renovation, we can
            help you get the job done right.
          </p>
        </ServiceCard>
        <ServiceCard>
          <h3>Steel Framing, and Ceiling Tile Grid</h3>
          <img
            src={grid1}
            alt='Steel Framing/Ceiling Tile'
          />
          <p>
            In addition to installation and finishing, we also provide drywall
            repairs, steel framing, and ceiling tile grid services. Our team has
            the expertise to handle any job.
          </p>
        </ServiceCard>
      </ServicesWrapper>
    </HomeWrapper>
  );
};

export default Home;