import Home from './components/home';
import Quote from './components/quote';
import NavBar from './components/navbar';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './components/about';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path='/'  element={<Home/>}/>
        <Route path='/about' element={<About/>} />
        <Route path='/quote' element={<Quote/>} />
      </Routes>
    </Router>
  );
}

export default App;
