import React, { useState } from 'react';
import { NavWrapper, NavLogo, NavMenu, NavItem, Hamburger, HamburgerLine } from './styled/navbar.style';
import { Link } from 'react-router-dom';
import logo from './images/kmkupscale.jpg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavWrapper isOpen={isOpen}>
      <Link to="/">
        <NavLogo src={logo} alt="KMK Drywall Logo" />
      </Link>
      <Hamburger onClick={toggleNav}>
        <HamburgerLine isOpen={isOpen} />
        <HamburgerLine isOpen={isOpen} />
        <HamburgerLine isOpen={isOpen} />
      </Hamburger>
      <NavMenu isOpen={isOpen}>
        <NavItem>
          <Link to="/about">
            About Us
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/quote">
            Request a Quote
          </Link>
        </NavItem>
      </NavMenu>
    </NavWrapper>
  );
};

export default Navbar;