import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';


export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  a {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    max-height: 250px;
    max-width: 300px;
  }
`;

export const ServicesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0;
`;

export const ServiceCard = styled.div`
  flex: 0 0 calc(33.333% - 20px);
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;

  h3 {
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }

  img {
    display: block;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`;
