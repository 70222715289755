import styled from 'styled-components';

export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const AboutContent = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  a {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
`;

export const GalleryItem = styled.div`
  flex: 0 0 100%;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
  }
}
`;