import styled from 'styled-components';

export const NavWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 999;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    height: auto;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
`;

export const NavLogo = styled.img`
  height: 50px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const NavMenu = styled.ul`
  list-style: none;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
  }
`;

export const NavItem = styled.li`
  margin: 0 10px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;

    &:hover {
      color: #ccc;
    }
  }
`;

export const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;
  }
`;

export const HamburgerLine = styled.div`
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: transform 0.3s ease-in-out;

  ${props => props.isOpen && `transform: rotate(45deg) translate(-5px, 5px);`}

  &:nth-child(2) {
    ${props => props.isOpen && `opacity: 0;`}
  }

  &:nth-child(3) {
    width: 20px;
    ${props => props.isOpen && `transform: rotate(-45deg) translate(-4px, -4px);`}
  }
`;
