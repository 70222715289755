import React, { useState, useEffect } from 'react';
import { AboutWrapper, AboutContent, GalleryWrapper, GalleryItem } from './styled/about.style';
import steelFramingImg from './images/IMG_2721.jpg';
import commercialDrywallImg from './images/IMG_2734.jpg';
import residentialDrywallImg from './images/IMG_4199.jpg';
import drywallRepairsImg from './images/IMG_4199.jpg';
import ImageGallery from 'react-image-gallery';

const About = () => {

  const [steelFramingImages, setSteelFramingImages] = useState([]);
  const [commercialDrywallImages, setCommercialDrywallImages] = useState([]);
  const [residentialDrywallImages, setResidentialDrywallImages] = useState([]);
  const [ceilingGridImages, setCeilingGridImages] = useState([]);

  const loadGalleryImages = async (setImagesFunc, imagePrefix, imageCount) => {
    const images = [];
    for (let i = 1; i <= imageCount; i++) {
      const image = await import(`./images/${imagePrefix}${i}.jpg`); // Adjust the path and extension as necessary
      images.push({
        original: image.default,
        thumbnail: image.default
      });
    }
    setImagesFunc(images);
  };

  useEffect(() => {
    loadGalleryImages(setSteelFramingImages, 'steel', 1); 
    loadGalleryImages(setCommercialDrywallImages, 'comm', 5); 
    loadGalleryImages(setResidentialDrywallImages, 'res', 6); 
    loadGalleryImages(setCeilingGridImages, 'grid', 3); 
  }, []);

  return (
    <AboutWrapper>
      <AboutContent>
        <h2>About KMK Drywall</h2>
        <p>
        KMK Drywall is a family-owned company based in Springville, NY. We are fully insured and have been providing commercial and residential drywall installation, finishing, steel framing, and ceiling tile grid services throughout Western New York. With our dedicated team and commitment to quality, we guarantee exceptional results for all your drywall needs. You can reach us at <a href='tel:+17163532989'>(716) 353-2989</a> or <a href="mailto:kmkdrywall8900@gmail.com">kmkdrywall8900@gmail.com</a>.
        </p>
        <GalleryWrapper>
        <GalleryItem>
          <h3>Residential Drywall</h3>
          <ImageGallery
             items={residentialDrywallImages} 
             alt="Steel Framing Gallery" />
          </GalleryItem>
          <GalleryItem>
          <h3>Commcercial Drywall</h3>
          <ImageGallery
             items={commercialDrywallImages} 
             alt="Commercial Drywall Gallery" />
          </GalleryItem>
          <GalleryItem>
          <h3>Acoustical Ceilings</h3>
          <ImageGallery
             items={ceilingGridImages} 
             alt="Steel Framing Gallery" />
          </GalleryItem>
          <GalleryItem>
            <h3>Steel Framing</h3>
            <ImageGallery
             items={steelFramingImages} 
             alt="Steel Framing Gallery" />
          </GalleryItem>
        </GalleryWrapper>
      </AboutContent>
    </AboutWrapper>
  );
};

export default About;
